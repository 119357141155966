import { ref } from "vue";
import { projectFirestore } from "../firebase/config";
import { addDoc as _addDoc, collection as _collection, doc as _doc, setDoc, deleteDoc, query, getDocs, QuerySnapshot, updateDoc } from "firebase/firestore";

const useCollection = (collection) => {
    const error = ref(null);

    const getAllDocsWithPath = async (path) => {
        console.log("in getalldocs");
        error.value = null;
        try {
            const querySnapshot = await getDocs(_collection(projectFirestore, `${collection}/${user.value.uid}/${path}`));
            const documents = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            return documents;
        } catch (err) {
            console.log(err.message);
            error.value = err.message;
        }
    }


    const addDoc = async (doc) => {
        error.value = null;

        try {
            await addDoc(_collection(projectFirestore, collection), doc);
        } catch (err) {
            console.log(err.message);
            error.value = err.message;
        }
    }

    const updateDocWithPath = async (path, id, fieldsToUpdate) => {
        error.value = null;
        try {
            const docRef = await _doc(_collection(projectFirestore, `${path}`), id);
            await updateDoc(docRef, fieldsToUpdate);
        } catch (error) {
            console.log('Error updating document: ', error);
            error.value = error.message;
        }
    }

    const updateDocWithDirectPath = async (path, id, fieldsToUpdate) => {
        error.value = null;
        try {
            const docRef = await _doc(_collection(projectFirestore, `${path}`), id);
            await updateDoc(docRef, fieldsToUpdate);
        } catch (error) {
            console.log('Error updating document: ', error);
            error.value = error.message;
        }
    }

    const deleteDocWithPath = async (path, id) => {
        error.value = null;
        try {
            const docRef = await _doc(_collection(projectFirestore, `${path}`), id);
            await deleteDoc(docRef);
        } catch (error) {
            console.log('Error deleting document: ', error);
            error.value = error.message;
        }
    }

    const queryAndDelete = async (path, field, value) => {
        error.value = null;
        try {
            const q = query(_collection(projectFirestore, `${collection}/${user.value.uid}/${path}`), where(field, '==', value));
            let qSnap = await getDocs(q);
            console.log(qSnap);
            // qSnap.forEach(async (doc) => {
            //     await deleteDoc(doc.ref);
            // });
        } catch (error) {
            console.log('Error deleting document: ', error);
            error.value = error.message;
        }
    }


    const addDocWithPath = async (path, data) => {
        console.log(`${collection}/${path}`);
        error.value = null;
        try {
            const docRef = await _addDoc(_collection(projectFirestore, `${collection}/${path}`), data);
            // await setDoc(docRef, doc);
            return docRef.id;
        } catch (error) {
            console.log('Error adding document: ', error);
            error.value = error.message;
        }
    }

    const addDocWithPathAndId = async (path, data, id) => {
        console.log(`${collection}/${path}`);
        error.value = null;
        try {
            const docRef = await _doc(_collection(projectFirestore, `${collection}/${path}`), id);
            await setDoc(docRef, data);

        } catch (error) {
            console.log('Error adding document: ', error);
            error.value = error.message;
        }


    }
    return { error, addDoc, addDocWithPath, addDocWithPathAndId, deleteDocWithPath, queryAndDelete, updateDocWithPath, getAllDocsWithPath, updateDocWithDirectPath }
}


export default useCollection;