<template>
  <div>
    <button @click="loginWithBiometrics">Login with Biometrics</button>
  </div>
</template>

<script>
import { ref } from "vue";
import useLogin from "../composables/useLogin";

export default {
  setup(props, context) {
    const { error, loginCredential, isPending } = useLogin();

    const loginWithBiometrics = async () => {
      try {
        const credential = await requestBiometricCredential();
        if (credential) {
          console.log(credential);
          await loginCredential(credential);
          if (!error.value) {
            context.emit("login");
          }
        }
      } catch (err) {
        console.error(err);
      }
    };

    const requestBiometricCredential = async () => {
      try {
        const credentialOptions = {
          publicKey: {
            challenge: Uint8Array.from("meow", (c) =>
              c.charCodeAt(0)
            ), // Generate a unique challenge
            // userVerification: "required", // Ensure biometric verification is required
            rp: { name: "AM Status", id: "localhost" },
            user: {
              id: Uint8Array.from("UZSL85T9AFC", (c) => c.charCodeAt(0)),
              name: "aginn@afumc.org",
              displayName: "aginn",
            },
            pubKeyCredParams: [{ type: "public-key", alg: -7 }],
            authenticatorSelection: {
              authenticatorAttachment: "cross-platform",
            },
          },
        };

        const credential = await navigator.credentials.create({
          publicKey: credentialOptions.publicKey,
        });
        return credential;
      } catch (error) {
        console.error("Error requesting biometric credential:", error);
        return null;
      }
    };

    return {
      loginWithBiometrics,
    };
  },
};
</script>
