<template>
  <ModalForm v-if="isModalOpen" @closeModal="closeModal"></ModalForm>
  <ModalDelete
    v-if="isModalDeleteOpen"
    :yo="propPass"
    @closeDeleteModal="closeDeleteModal"
    @deleteHost="deleteHost"
  ></ModalDelete>
  <nav class="navbar">
    <div>
      <h1 class="navbar-title">Status</h1>
    </div>

    <div class="subscribe">
      <!-- <button class="button-updates">Subscribe to Updates</button> -->
      <button @click="openModal" class="button-updates">Add Device</button>
      <button @click="handleLogout" class="button-updates">Log Out</button>
    </div>
  </nav>
  <div v-if="wan" class="content">
    <div
      v-if="wan"
      id="wan"
      :class="wan.status === 'Online' ? 'online' : 'offline'"
    >
      <span id="wan-title">WAN {{ wan.status }}</span>
      <span v-if="wan.status === 'Offline'" class="last-seen">
        - {{ convertToDatetime(wan.timestamp) }}</span
      >
    </div>
    <div
      v-if="wan.status === 'Online' && wan.probeLatency"
      id="processing"
      :class="
        wan.probeLatency < 200
          ? 'green'
          : wan.probeLatency < 500 && wan.probeLatency > 200
          ? 'yellow'
          : 'red'
      "
    >
      <span
        >Probe Performance - {{ Math.round(wan.probeLatency) }}ms |
        {{ Math.round(wan.temp) }}&#176;C
      </span>
    </div>
    <div
      v-if="wan.status === 'Online' && config"
      :class="config.wol ? 'red' : 'grey'"
      id="processing"
      class="pointer"
      @click="wolExec"
    >
      <span id="wol">Wake On Lan</span>
    </div>
    <div id="hosts">
      <div v-for="host in orderedHosts" :key="host.id" class="host-card">
        <span class="delete" @click="openModalDelete(host)">&times;</span>
        <div>
          <span class="host-name">{{ host.name }}</span> -
          <span
            :class="
              wan.status === 'Offline'
                ? 'pill-yellow'
                : host.status === 'Online'
                ? 'pill-green'
                : host.status === 'Offline'
                ? 'pill-red'
                : 'yo'
            "
          >
            <span v-if="wan.status === 'Online'">{{ host.status }}</span>
            <span v-else>Unknown</span>
          </span>
        </div>
        <div>
          <span class="ip">{{ host.host }}</span>
        </div>
        <div v-if="host.status !== 'Online'">
          <span v-if="host.lastSeen" class="last-seen"
            >Last online - {{ convertToDatetime(host.lastSeen) }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import getCollection from "@/composables/getCollection.js";
import useCollection from "@/composables/useCollection";
import getDoc from "@/composables/getDoc.js";
import { Timestamp } from "firebase/firestore";
import ModalForm from "@/components/ModalForm.vue";
import { ref, computed } from "vue";
import ModalDelete from "@/components/ModalDelete.vue";
import useLogout from "@/composables/useLogout";
import getUser from "../composables/getUser";
import { useRouter } from "vue-router";
import { watch } from "vue";

export default {
  components: {
    ModalForm,
    ModalDelete,
  },
  setup() {
    const { documents: hosts, error } = getCollection("hosts");
    const { document: wan, error: wanErr } = getDoc("wan", "status");
    const { document: performance, error: performanceErr } = getDoc(
      "timings",
      "probes"
    );
    const { document: config, error: configErr } = getDoc("config", "config");
    const { deleteDocWithPath, updateDocWithPath } = useCollection("");
    const { logout, error: logoutErr } = useLogout();

    const isModalOpen = ref(false);
    const isModalDeleteOpen = ref(false);
    const propPass = ref({});

    const { user } = getUser();
    const router = useRouter();

    watch(user, () => {
      if (!user.value) {
        router.push({ name: "welcome" });
      }
    });

    const wolExec = async () => {
      console.log("wol");
      await updateDocWithPath("config", "config", { wol: true });
    };

    const openModal = () => {
      isModalOpen.value = true;
    };

    const openModalDelete = (pass) => {
      console.log(pass);
      propPass.value = pass;
      isModalDeleteOpen.value = true;
    };

    const deleteHost = async (id) => {
      console.log("deleting: ", id);
      isModalDeleteOpen.value = false;
      await deleteDocWithPath("hosts", id);
      await deleteDocWithPath("probe", id);
    };

    const closeModal = () => {
      propPass.value = {};
      isModalOpen.value = false;
    };

    const closeDeleteModal = () => {
      isModalDeleteOpen.value = false;
    };

    const checkOnline = () => {
      if (wan.value().status == "Online") {
        console.log(wan.value().status);
        return true;
      }
      return false;
    };

    const convertToDatetime = (timestamp) => {
      const milliseconds = timestamp.toMillis();
      const date = new Date(milliseconds);
      const estOptions = {
        timeZone: "America/New_York",
        timeZoneName: "short",
      };
      const datetimeString = date.toLocaleString("en-US", estOptions); // Format to "YYYY-MM-DDTHH:mm"
      return datetimeString;
    };

    const orderedHosts = computed(() => {
      const offlineHosts = hosts.value.filter(
        (doc) => doc.status === "Offline"
      );
      const onlineHosts = hosts.value.filter((doc) => doc.status !== "Offline");
      return [...offlineHosts, ...onlineHosts];
    });
    
    const handleLogout = async () => {
      await logout();
      if (!error.value) {
        // Redirect to login page
        console.log("Logged out");
      }
    }
    

    return {
      hosts,
      wan,
      convertToDatetime,
      openModal,
      closeModal,
      isModalOpen,
      checkOnline,
      performance,
      deleteHost,
      config,
      wolExec,
      orderedHosts,
      isModalDeleteOpen,
      closeDeleteModal,
      openModalDelete,
      propPass,
      handleLogout
    };
  },
};
</script>
  
  <style>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  background-color: #121212;
  width: 100%;

  margin-bottom: 50px;
}
.navbar-title {
  font-size: 28px;
  font-weight: bold;
  padding-left: 30px;
}
.button-updates {
  background-color: #282828;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  margin-left: 10px;
  cursor: pointer;
}

.button-updates:hover {
  background-color: #fff;
  color: #282828;
}
.subscribe {
  position: relative;
  padding-right: 30px;
  text-align: right;
}

.content {
  /* display: flex;
    justify-content: center; */
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
}
#wan {
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  /* margin: 10px; */
  border-radius: 5px;
}

.online {
  background-color: lightgreen;
}

.offline {
  background-color: lightcoral;
}

.unknown {
  background-color: lightyellow;
}

#wan-title {
  padding-left: 20px;
}

#hosts {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
}

.host-card {
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
  border-radius: 5px;
  padding: 20px;
  position: relative;
}

.pill-green {
  display: inline-block;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 200px; /* Adjust this value to control the roundness of the pill */
  background-color: lightgreen; /* Choose the background color you want */
}

.pill-red {
  display: inline-block;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 200px; /* Adjust this value to control the roundness of the pill */
  background-color: lightcoral; /* Choose the background color you want */
}

.pill-yellow {
  display: inline-block;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 200px; /* Adjust this value to control the roundness of the pill */
  background-color: rgb(
    255,
    255,
    84
  ); /* Choose the background color you want */
  color: #1a1a1a;
}

.host-name {
  font-weight: bold;
}
#processing {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  /* margin: 10px; */
  border-radius: 5px;
  /* background-color: #1a1a1a; */
  padding-left: 20px;
  padding-right: 20px;
}

.green {
  background-color: lightgreen;
}

.grey {
  background-color: #1a1a1a;
}

.yellow {
  background-color: rgb(255, 255, 84);
  color: #1a1a1a;
}

.red {
  background-color: lightcoral;
}
.delete {
  position: absolute;
  top: 0px;
  right: 7px;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
}

.delete:hover {
  color: lightcoral;
}
#wol {
  height: 100%;
  width: 100%;
  text-align: left;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  background-color: #fff;
  color: #1a1a1a;
}
</style>