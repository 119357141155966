<template>
  <div class="modal">
    <span class="close" id="closeModalBtn" @click="closeModal">&times;</span>
    <div class="modal-content">
        
      <h2>Add Host</h2>
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="name">Name</label>
          <input v-model="formData.name" type="text" id="name" required />
        </div>
        <div class="form-group">
          <label for="ip">IP Address</label>
          <input v-model="formData.host" type="text" id="ip" required />
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <textarea
            v-model="formData.description"
            id="description"
            required
          ></textarea>
        </div>
        <div class="form-group" id="select-wol">
          <label for="description">Wake On Lan</label>
          <select v-model="formData.wol" id="wol" required>
            <option :value="true" selected>Enabled</option>
            <option :value="false">Disabled</option>
          </select>
          </div>
        <div v-if="formData.wol" class="form-group">
          <label for="description">MAC Address</label>
          <input
            v-model="formData.mac"
            id="mac"
            required
          />
        </div>
        <button type="submit">Add</button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import useCollection from '@/composables/useCollection.js'

export default {
  setup(props, context) {
    const { addDocWithPath, addDocWithPathAndId } = useCollection("");

    const formData = ref({
      name: "",
      host: "",
      description: "",
      status: "Offline",
      mac: "",
      wol: false,
    });

    const resetFormData = () => {
      formData.Value = {
        name: "",
        host: "",
        description: "",
        status: "Offline",
        wol: false,
        mac: ""
      };
    };

    const closeModal = () => {
      resetFormData();
      context.emit("closeModal");
    };

    const submitForm = async () => {
        const id = await addDocWithPath("hosts", formData.value);
        await addDocWithPathAndId("probe", {
          host: formData.value.host,
          mac: formData.value.mac,
          wol: formData.value.wol,
          }, id);

        closeModal();

    };

    return {
      formData,
      submitForm,
      closeModal
    };
  },
};
</script>

<style scoped>
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 1000;
    width: 75%;
    max-width: 500px;
}
h2 {
    text-align: center;
}

.modal-content {
    background-color: #2b2b2b;
    margin: 0;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    /* text-align: center; */
}

.close {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
}

.form {
  /* Form styles */
  /* width: 300px; */
}

.form-group {
  margin-bottom: 10px;
  padding-right: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 4px;
}

input,
textarea,select {
  width: 100%;
  padding: 8px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  background-color: #fff;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-content: right;
  background-color: #fff;
}

.cancel-button {
  background-color: #ccc;
  color: white;
}

#select-wol {
  padding-right: 0;
}
</style>
  