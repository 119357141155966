import { ref } from "vue";
import { projectFirestore } from "../firebase/config";
import { collection as _collection, onSnapshot, orderBy as _orderBy, doc } from "firebase/firestore";


const getCollection = (collection, orderBy) => {
    const documents = ref(null);
    const error = ref(null);

    console.log(collection);

    let collectionRef = _collection(projectFirestore, `${collection}`);
    // if(orderBy){
    //     _orderBy(collectionRef, orderBy);
    // }
   

    onSnapshot(collectionRef, (snap) => {
        let results = [];
        snap.forEach((doc) => {
            results.push({...doc.data(), id: doc.id});
        });
        console.log(results);
        documents.value = results;
        error.value = null;
    }, (err) => {
        console.log(err.message);
        documents.value = null;
        error.value = err.message;
    });

    return { documents, error }
}

export default getCollection;