import { ref } from 'vue';
import { projectAuth } from '../firebase/config';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signInWithCredential } from "firebase/auth";

const error = ref(null);
const isPending = ref(false);

const loginGoogle = async () => {
    error.value = null;
    isPending.value = true;
    try {
        const provider = new GoogleAuthProvider();
        const res = await signInWithPopup(projectAuth, provider);
        error.value = null;
        console.log(res);
        isPending.value = false;
        return res;
    } catch (err) {
        console.log(err.message);
        error.value = "Incorrect login credentials";
        isPending.value = false;
    }
}

const login = async (email, password) => {
    error.value = null;
    isPending.value = true;

    try {
        const res = await signInWithEmailAndPassword(projectAuth, email, password);
        error.value = null;
        console.log(res);
        isPending.value = false;
        return res;
    } catch (err) {
        console.log(err.message);
        error.value = "Incorrect login credentials";
        isPending.value = false;
    }
}

const loginCredential = async (credential) => {
    error.value = null;
    isPending.value = true;

    try {
        const res = await signInWithCredential(projectAuth, credential);
        error.value = null;
        console.log(res);
        isPending.value = false;
        return res;
    } catch (err) {
        console.log(err.message);
        error.value = "Incorrect login credentials";
        isPending.value = false;
    }
}

const useLogin = () => {
    return { error, login, isPending, loginGoogle, loginCredential }
}

export default useLogin;