// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDglHP65FN1UJzvTKAcztTEHxDhXEgIO04",
    authDomain: "am-probe-7ed9b.firebaseapp.com",
    projectId: "am-probe-7ed9b",
    storageBucket: "am-probe-7ed9b.appspot.com",
    messagingSenderId: "938604666780",
    appId: "1:938604666780:web:9ecbbdf1c1cb523e28f259"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//init firestore service
const projectFirestore = getFirestore(app);
const projectAuth = getAuth(app);
const projectStorage = getStorage(app);
const timestamp = serverTimestamp();

export { projectFirestore, timestamp, projectAuth, projectStorage };