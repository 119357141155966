import { createRouter, createWebHistory } from 'vue-router'
import WelcomeView from '../views/WelcomeView.vue'
import { projectAuth } from '../firebase/config';
import PortalView from '../views/PortalView.vue'

// auth guard
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  if(!user){
    next({ name: 'welcome' });
  }else {
    next();
  }
}

//already logged in
const requireNoAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  if(user){
    next({ name: 'portal' });
  }else {
    next();
  }
}

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: WelcomeView,
    beforeEnter: requireNoAuth
  },
  {
    path: '/portal',
    name: 'portal',
    component: PortalView,
    beforeEnter: requireAuth
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
