<template>
  <div class="welcome-container">
    <div class="form-container">
      <div v-if="showLogin">
        <h1>AM Status</h1>
        <LoginForm @login="enterDash" />
        <!-- <BiometricLogin @login="enterDash" /> -->
        <div class="forgot-password">
          <a href="#">Forgot Password?</a>
        </div>
        <!-- <div class="signup">
            Don't have an account?
            <a href="#" @click="showLogin = false">Sign up</a>
          </div> -->
      </div>
      <!-- <div v-else>
          <h1>Sign up</h1>
          <SignUpForm @signup="enterDash" />
          <div class="signup">
            Already have an account?
            <a href="#" @click="showLogin = true">Login</a>
          </div>
        </div> -->
    </div>
  </div>
</template>
  
  <script>
import { ref } from "vue";
import LoginForm from "../components/LoginForm.vue";
//   import SignUpForm from "../components/SignUpForm.vue";
import { useRouter } from "vue-router";
import BiometricLogin from "@/components/BiometricLogin.vue";

export default {
  components: {
    LoginForm,
    BiometricLogin,
  },
  setup() {
    const showLogin = ref(true);
    const router = useRouter();

    const enterDash = () => {
      console.log("enterDash");
      router.push({ name: "portal" });
    };
    return {
      showLogin,
      enterDash,
    };
  },
};
</script>
  
  <style>
.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #121212;
  overflow: hidden;
  /* Hide the scroll bar */
}

.logo-container {
  margin-bottom: 30px;
  text-align: center;
}

.logo-container img {
  width: 80px;
}

.form-container {
  max-width: 350px;
  padding: 30px;
  background-color: #1a1a1a;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.error {
  /* max-width: 200px; */
  color: crimson;
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
  margin-right: 0px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  color: #fff;
}
.form-group {
  margin-bottom: 20px;
}

.input-field {
  padding: 12px;
  font-size: 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 3px;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: #1fbad6;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
button[type="submit"].loading {
  background-color: #b7b7b7;
}
button[type="submit"]:hover {
  background-color: #0fa9c0;
}
button[type="submit"].loading:hover {
  background-color: #b7b7b7;
}

.forgot-password {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #ccc !important;
}

.forgot-password a {
  color: #1fbad6;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

.signup {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #ccc;
}

.signup a {
  color: #1fbad6;
  text-decoration: none;
}

.signup a:hover {
  text-decoration: underline;
}

/* Hide the scroll bar */
::-webkit-scrollbar {
  width: 0.5em;
  background-color: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background-color: #333;
}
</style>