import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { projectAuth } from './firebase/config'
import { onAuthStateChanged } from 'firebase/auth';

let app;

onAuthStateChanged(projectAuth, () => {
    console.log('user state changed');
    if (!app) {
        app = createApp(App)
            .use(router)
            .mount('#app');
    }
});
