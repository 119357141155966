<template>
    <form @submit.prevent="handleSubmit">
      <div class="error">{{error}}</div>
      <div class="form-group">
        <input
          type="text"
          id="email"
          required
          v-model="email"
          placeholder="Email"
          class="input-field"
        />
      </div>
      <div class="form-group">
        <input
          type="password"
          id="password"
          required
          v-model="password"
          placeholder="Password"
          class="input-field"
        />
      </div>
      <button v-if="!isPending" type="submit">Log In</button>
      <button v-if="isPending" class="loading" type="submit" disabled>Loading</button>
    </form>
  </template>
  
  <script>
  import { ref } from "vue";
  import useLogin from "../composables/useLogin";
  
  export default {
    setup(props, context) {
      const { error, login, isPending } = useLogin();
  
      const email = ref("");
      const password = ref("");
  
      const handleSubmit = async () => {
        await login(email.value, password.value);
        if (!error.value) {
          context.emit("login");
        }
      };
  
      return {
        email,
        password,
        handleSubmit,
        error,
        isPending
      };
    },
  };
  </script>
  
  <style scoped>
  </style>