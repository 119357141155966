import { ref } from "vue";
import { projectFirestore } from "../firebase/config";
import { onSnapshot, doc } from "firebase/firestore";

const getDoc = (collection, id) => {
    const document = ref(null);
    const error = ref(null);

    console.log(collection, id);

    let docRef = doc(projectFirestore, `${collection}`, id);
   

    onSnapshot(docRef, (snap) => {
        let result = snap.data();
        console.log(result);
        document.value = result;
        error.value = null;
    }, (err) => {
        console.log(err.message);
        document.value = null;
        error.value = err.message;
    });

    return { document, error }
}

export default getDoc;