<template>
    <div class="modal">
      <span class="close" id="closeModalBtn" @click="closeModal">&times;</span>
      <div class="modal-content">
          
        <h2>Are you sure you want to delete {{ props.yo.name }}?</h2>
          <button type="submit" @click.prevent="deleteProbe(props.yo.id)">Delete</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    props: {
        yo: Object
    },
    setup(props, context) {

        console.log(props.yo);
      
      const closeModal = () => {
        context.emit("closeDeleteModal");
      };

      const deleteProbe = (id) => {
        console.log(
        )
        context.emit("deleteHost", id);
        closeModal();
      };
  
      return {
        closeModal,
        props,
        deleteProbe
      };
    },
  };
  </script>
  
  <style scoped>
  .modal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /* background-color: rgba(0, 0, 0, 0.5); */
      z-index: 1000;
      width: 75%;
      max-width: 500px;
  }
  h2 {
      text-align: center;
  }
  
  .modal-content {
      background-color: #2b2b2b;
      margin: 0;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      text-align: center;
  }
  
  .close {
      position: absolute;
      top: 5px;
      right: 15px;
      font-size: 24px;
      cursor: pointer;
  }
  
  .form {
    /* Form styles */
    /* width: 300px; */
  }
  
  .form-group {
    margin-bottom: 10px;
    padding-right: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  input,
  textarea,select {
    width: 100%;
    padding: 8px;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    background-color: #fff;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  button {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-content: right;
    background-color: #fff;
  }
  
  .cancel-button {
    background-color: #ccc;
    color: white;
  }
  
  #select-wol {
    padding-right: 0;
  }
  </style>
    