import { ref } from 'vue';
import { projectAuth } from '../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';


const user = ref(projectAuth.currentUser);
const photoURL = ref("");

onAuthStateChanged(projectAuth, (_user) => {
    console.log('User state change. Current user is: ', _user);
    user.value = _user;
    if (_user){
        photoURL.value = _user.photoURL;
    }
    
});

const getUser = () => { 
    return { user, photoURL }
}

export default getUser;