<template>
    <router-view></router-view>
</template>

<script>
import PortalView from "./views/PortalView.vue";
import WelcomeView from "./views/WelcomeView.vue";

export default {
  name: "App",

  components: {
    PortalView,
    WelcomeView,
  },
  data() {
    return {
      isLoggedIn: false,
    };
  },
};
</script>
<style>
  html,
  body {
    background-color: #121212;
    margin: 0;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #fff;
    /* margin-top: 60px;  */
  }
</style>